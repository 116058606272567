
































import { Component, Vue } from 'vue-property-decorator'
import CalculatorItem from '@/components/calculators/CalculatorItem.vue'
import Calculator from '@/shared/modules/calculator/calculator'
import http from '@/shared/http'
import User from '@/shared/modules/user/user'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import ResponseInterface from '@/shared/interfaces/response.interface'
import { Routes } from '@/shared/router'
import Client from '@/shared/modules/client/client'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarType } from '@/shared/helpers/snackbar.helper'
import CreateCalculatorDialog from '@/components/calculators/CreateCalculatorDialog.vue'
import can from '@/shared/helpers/can'
import { Permission } from '@/shared/configs/permission.config'

@Component({
  components: { CalculatorItem },
  methods: { can }
})
export default class Calculators extends Vue {
  calculators: Calculator[] = []
  loading: boolean = true
  Permission = Permission

  created() {
    this.loadCalculators()
  }

  loadCalculators() {
    if (! this.selectedClient) {
      this.loading = false

      return
    }

    http.get(`clients/${ this.selectedClient.uuid }/calculators`)
      .then((response: ResponseInterface<Calculator>) => {
        this.calculators = response.data.data.map((item: Calculator) => item)
        this.loading = false
      })
  }

  createCalculator() {
    this.$store.dispatch(GlobalActions.showDialog, {
      maxWidth: 800,
      component: CreateCalculatorDialog,
      meta: {
        client: this.selectedClient,
        onSuccess: () => {
          this.$store.dispatch(GlobalActions.showSnackbar, {
            type: SnackBarType.success,
            message: 'Calculator was successfully created',
          })

          this.loadCalculators()
        }
      }
    })
  }

  redirectToInner(calculator: Calculator) {
    this.$router.push({ name: Routes.calculatorsShow, params: { uuid: calculator.uuid } })
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }

  get selectedClient(): Client {
    return this.user.selected_client || this.user.clients[0]
  }
}
