





























import { Component, Prop, Vue } from 'vue-property-decorator'
import Calculator from '@/shared/modules/calculator/calculator'
import { CalculatorStatus } from '@/shared/configs/calculator-statuses'
import capitalize from '@/shared/helpers/capitalize'

@Component({
  methods: { capitalize }
})
export default class CalculatorItem extends Vue {
  @Prop() calculator!: Calculator
  CalculatorStatus = CalculatorStatus
}
